import * as Yup from "yup";

const ssnCardSchema = Yup.object({

});

export const ssnCardSchemaDynamic = (questions) =>
  Yup.object({
});

export type SsnCardSchema = Yup.InferType<typeof ssnCardSchema>;
export default ssnCardSchema;
