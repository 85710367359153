import React, { ChangeEvent, useEffect, useState } from "react";
import Spacer from "../spacer/Spacer";
import Textbox from "../../inputs/textbox/Textbox";
import { Divider, Typography } from "@material-ui/core";
import { buildInputName } from "../../utils/Utils";
import ZipInput from "../../inputs/zipInput/ZipInput";
import { CompProps } from "../../modules/onboardingForm/PageConfigs";
import { State }  from 'country-state-city';
import {CustomSelect as Select } from '../../inputs/select/Select';



const inputName = buildInputName("address");

export type OnChangeHandler = (
  event: any,
) => void

const Address: React.FC<CompProps> = ({ questions, sections }) =>
  {
    const [stateList, setStateList] = useState([]);
    const [selectedState, setSelectedState] = useState();

    useEffect( () => {
      setStateList(State.getStatesOfCountry('US').map(r=> ({label: r.name, value: r.isoCode})))
    },[]);

    const handleStateChange: OnChangeHandler = async (eve) => {
      const selected  = stateList.find(c=> c.label === eve?.label).value;
      setSelectedState(selected);
    }

  return (
  <Spacer direction="column">
    <>
      <Typography variant="h5">Home Address</Typography>
      <Typography variant="subtitle1">
        Please enter your current address.
      </Typography>
    </>

    {questions.includes("addressLineOne") && (
      <Textbox
        required={true}
        name={inputName("addressLineOne")}
        label="Address line one"
      />
    )}
    {questions.includes("addressLineTwo") && (
      <Textbox name={inputName("addressLineTwo")} label="Address line two" />
    )}

    <Spacer items={3}>
      {questions.includes("city") && (
        <Textbox required={true} name={inputName("city")} label="City" />
      )}
      {questions.includes("state") && (
        <Select name={inputName('state')} label='State'  options={stateList} onChange={handleStateChange}/>
      )}
      {questions.includes("zip") && (
        <ZipInput required={true} name={inputName("zip")} label="Zip" />
      )}
    </Spacer>
    <Divider />
  </Spacer>
);
}

export default Address;
