import * as Yup from "yup";
import fullNameSchema, {
  fullNameSchemaDynamic,
  fullNameSchemaMiddleRequiredDynamic,
  fullNameSchemaMiddleRequired,
} from "../../components/fullName/FullName.schema";
import contactInfoSchema, {
  contactInfoSchemaDynamic,
} from "../../components/contactInfo/ContactInfo.schema";
import addressSchema, {
  addressSchemaDynamic,
} from "../../components/address/Address.schema";
import aboutYouSchema, {
  aboutYouSchemaDynamic,
} from "./pages/aboutYou/AboutYou.schema";
import projectInfoSchema, {
  projectInfoSchemaDynamic,
} from "../../components/projectInfo/ProjectInfo.schema";
import selectiveServiceSchema, {
  selectiveServiceSchemaDynamic,
} from "./pages/SelectiveService/SelectiveService.schema";
import militaryServiceSchema, {
  militaryServiceSchemaDynamic,
} from "./pages/MilitaryService/MilitaryServiceSchema";
import backgroundInfoSchema, {
  backgroundInfoSchemaDynamic,
} from "./pages/backgroundInfo/BackgroundInfo.schema";
import additionalInfoSchema, {
  additionalInfoSchemaDynamic,
} from "./pages/additionalInfo/AdditionalInfo.schema";
import personalInfoSchema, {
  personalInfoSchemaDynamic,
} from "./pages/personalInfo/PersonalInfo.schema";
import vaInfoSchema, {
  vaInfoSchemaDynamic,
} from "./pages/vaInfo/VAInfo.schema";
import {
  preventAssaultInfoSchemaDynamic,
} from "./pages/preventAssaultInfo/PreventAssaultInfo.schema";
import { QuestionAnswer } from "@material-ui/icons";
import { credentialVerificationSchemaDynamic } from "./pages/credentialVerification/CredentialVerifiocation.schema";
import driversLicenseSchema, { driversLicenseSchemaDynamic } from "./pages/imagePDF/driversLicense/DriversLicense.schema";
import ssnCardSchema, { ssnCardSchemaDynamic } from "./pages/imagePDF/ssnCard/SSN.schema";

const onboardingFormSchemaDynamic = (quesConfigs) => {
  return Yup.object()
    .shape({
      fullName: fullNameSchemaMiddleRequiredDynamic(quesConfigs),
      otherNames: Yup.array()
        .of(fullNameSchemaDynamic(quesConfigs))
        .default([]),
      contactInfo: contactInfoSchemaDynamic(quesConfigs),
      address: addressSchemaDynamic(quesConfigs),
      aboutYou: aboutYouSchemaDynamic(quesConfigs),
      personalInfo: personalInfoSchemaDynamic(quesConfigs),
      projectInfo: projectInfoSchemaDynamic(quesConfigs),
      federalService: Yup.string()
        .default("")
        .required("Federal service is required."),
      selectiveService: selectiveServiceSchemaDynamic(quesConfigs),
      militaryService: militaryServiceSchemaDynamic(quesConfigs),
      backgroundInfo: backgroundInfoSchemaDynamic(quesConfigs),
      additionalInfo: additionalInfoSchemaDynamic(quesConfigs),
      vaInfo: vaInfoSchemaDynamic(quesConfigs),
      preventAssaultInfo: preventAssaultInfoSchemaDynamic(quesConfigs),
      credentialVerification: credentialVerificationSchemaDynamic(quesConfigs),
      driversLicense: driversLicenseSchemaDynamic(quesConfigs),
      ssnCard: ssnCardSchemaDynamic(quesConfigs)
    })
    .defined();
};

const onboardingFormSchema = Yup.object()
  .shape({
    fullName: fullNameSchemaMiddleRequired,
    otherNames: Yup.array().of(fullNameSchema).default([]),
    contactInfo: contactInfoSchema,
    address: addressSchema,
    aboutYou: aboutYouSchema,
    personalInfo: personalInfoSchema,
    projectInfo: projectInfoSchema,
    federalService: Yup.string()
      .default("")
      .required("Federal service is required."),
    selectiveService: selectiveServiceSchema,
    militaryService: militaryServiceSchema,
    backgroundInfo: backgroundInfoSchema,
    additionalInfo: additionalInfoSchema,
    vaInfo: vaInfoSchema,
    driversLicense: driversLicenseSchema,
    ssn: ssnCardSchema
  })
  .defined();

export type OnboardingFormSchema = Yup.InferType<typeof onboardingFormSchema>;
export default onboardingFormSchema;
export { onboardingFormSchemaDynamic };
