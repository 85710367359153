import React, { useState, useRef, ChangeEvent } from "react";
import { Button, Box, Typography, Divider } from "@material-ui/core";
import { generatePDF } from "../util";
import axios from "../../../../../axios";
import { Textbox } from "../../../../../inputs";



const SSNCardUpload: React.FC = () => {
  const [imageSrc, setImageSrc] = useState<string | null>(null);
  const [matchedText, setMatchedText] = useState<string>('');
  const [validDoc, setValidDoc] = useState<boolean>(true);
  const contentRef = useRef<HTMLDivElement>(null);
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  };


  // Handle file selection
  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const file = event.target.files?.[0];
    setValidDoc(true);
    if (file) {
      const reader = new FileReader();
      const formData = new FormData();
     formData.append("file", file);
    axios.post<any>('documents/validate', formData, {
      headers,
    }).then(resp => {
      if(resp.data?.valid){
        setValidDoc(true);
        setMatchedText(resp.data?.detected_text)
      }else{
        setValidDoc(false);
      }
    })
      reader.onload = () => {
          setImageSrc(reader.result as string);
          generatePDF(contentRef, 'ssnCardPDF');
      }
      reader.readAsDataURL(file);
    }
  };

  return (
    <>
    <Typography variant="h5">Social Security Card</Typography><br/>
    <Typography variant="h6">Insert a photo of your Social Security Card below</Typography><br/>
    <Typography variant="caption">Please upload a clear and legible copy of your Social Security card. This document is necessary for employment verification, payroll processing, and VetPro credentialing. Ensure that the image is not blurred and that all information is fully visible. Acceptable formats include PDF, JPG, and PNG.</Typography><br/>
    <Divider />
    <br/><br/>
    <Box>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="ssn-card-file-input"
        type="file"
        onChange={handleFileChange}
      />
      <Textbox
          required={true}
          name={"ssn"}
          label="SSN"
          style={{ display: "none" }}
          value={matchedText}
        />
      <label htmlFor="ssn-card-file-input">
        <Button variant="contained" color="primary" component="span">
          Upload
        </Button>
      </label>
      <br/> <br/>
      {!validDoc && (
        <h1 style={
          {fontFamily: "sans-serif", color: "red"}
          }>Please upload a valid Social Security Card</h1>
      )}

      {imageSrc && (
        <div ref={contentRef}>
            <br/><br/>
          <h1 style={
            {fontFamily: "sans-serif", color: "#323285"}
            }>Social Security Card</h1><br/>
          <img
            src={imageSrc}
            alt="Selected"
            style={{ width: "100%", maxWidth: 600 }}
          />
          <p>-</p>
        </div>
      )}
    </Box>
    </>
  );
};

export { SSNCardUpload };
export default SSNCardUpload;