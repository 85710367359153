import * as Yup from "yup";

const driversLicenseSchema = Yup.object({
});

export const driversLicenseSchemaDynamic = (questions) =>
  Yup.object({
});

export type DriversLicenseSchema = Yup.InferType<typeof driversLicenseSchema>;
export default driversLicenseSchema;