import {
  Box,
  Button,
  CircularProgress,
  Divider,
  List,
  Link,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@material-ui/core";
import React from "react";
import { useRouteMatch } from "react-router";
import { useHistory } from "react-router-dom";
import { useLocation } from "react-router-dom";
import axios from "../../axios";
import { AxiosResponse } from "axios";
import Spacer from "../../components/spacer/Spacer";
import { downloadFile } from "../../utils/Utils";
import FileUpload, {
  FileUploadState,
} from "../../inputs/fileUpload/FileUpload";
import jwt from "jwt-decode";
import ClipLoader from "react-spinners/ClipLoader";
import { css } from "@emotion/react";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Progress from "../../components/Progress";
import { openDB } from 'idb';

type RouteMatchProps = {
  id: string;
};

type DraftSubmission = {
  form: string;
  name: string;
  filename: string;
  complete: boolean;
};

// Can be a string as well. Need to ensure each key-value pair ends with ;
const override = css`
  display: block;
  margin: 0 auto;
  border-color: green;
`;

const description = [
  "Download all forms - review for accuracy - DO NOT EDIT FORMS – click “make edits” below and re-generate forms to correct errors.",
  "Do not press back button.",
  "Follow the instructions to complete each form (see table below for specific instructions). For the forms requiring a wet signature in ink, print them, sign them in ink (digital signatures are NOT acceptable), scan or take a photo of the signed forms and save them as PDF documents.",
  "Click “Next” to upload the pdf scans of the forms.",
];

const specialFormsDescription = [
  "If any forms below are marked “Manual Form” they cannot be filled out by the automated system due to government requirements. Please manually fill them out consistently with your previous answers and upload them on the next screen.",
];

const Overview: React.FC = () => {
  const [state, setState] = React.useState<DraftSubmission[]>([]);
  const [username, setUsername] = React.useState<String>("");
  const [isUpload, setIsUpload] = React.useState<boolean>(false);
  const [loading, setLoading] = React.useState(true);
  const [progress, setProgress] = React.useState<number>(75);
  const [uploadLoading, setUploadLoading] = React.useState<boolean>(false);
  const [downloadLoading, setDownloadLoading] = React.useState<boolean>(false);
  const [requiredForms, setRequiredForms] = React.useState<["", ""]>();
  const headers = {
    Authorization: `Bearer ${localStorage.getItem("access-token")}`,
  };
  const user_id = jwt(localStorage.getItem("access-token"));
  const {
    params: { id },
  } = useRouteMatch<RouteMatchProps>(`/form/:id/sign/overview`);
  const status = useLocation();

  React.useEffect(() => {
    setInterval(() => {
      setProgress((prevProgress) => {
        if (prevProgress + 2 == 100) {
          return prevProgress;
        }
        return prevProgress >= 90
          ? prevProgress + 1
          : prevProgress + Math.floor(Math.random() * 4) + 1;
      });
    }, 1000);

    axios
      .get(`users/${user_id["user_id"]}`, { headers })
      .then((res) => setUsername(res.data.name));
    // console.log('Status from overview page',state.props.match.params.status);
    async function fetchAndFillForms() {
      const requiredForms = await getUserForms();
      const formStatus = "GENERATED";
      console.log("USERFORMS Assigned to this user", requiredForms);
      if (requiredForms && requiredForms.length > 0) {
        console.log(
          "requiredForms.size Assigned to this user",
          requiredForms.length
        );
        console.log("requiredForms::", requiredForms);
        await fillAndUploadUserForms(requiredForms);
        await axios
          .put(`users/${user_id["user_id"]}`, { formStatus }, { headers })
          .then(() =>
            //toast('Generated and uploaded forms successfully')
            {}
          );
          await generateDefaultPdfs();
      } else {
        setLoading(false);
      }
      async function generateDefaultPdfs() {
        const db = await openDB('pdfStorage', 1);
        const dlPdf = await db.get('pdfs', 'driversLicensePDF');
        const ssnPdf = await db.get('pdfs', 'ssnCardPDF');
        const dlFormData = new FormData();
        dlFormData.append('file', dlPdf?.pdfBlob, 'driversLicense.pdf');
        const ssnFormData = new FormData();
        ssnFormData.append('file', ssnPdf?.pdfBlob, 'ssnCard.pdf');
        try {
          const resp = await axios.post<string>(`forms/uploads/${id}/dl`, dlFormData, {
            headers,
          });
        } catch (err) {
          console.log('err' + err);
        }
        try {
          const resp = await axios.post<string>(`forms/uploads/${id}/ssn`, ssnFormData, {
            headers,
          });
        } catch (err) {
          console.log('err' + err);
        }
      }
    }
    async function fetchFilledForms() {
      const requiredForms = await getUserForms();
      console.log("UESERFORMS Assigned to this user", requiredForms);
      if (requiredForms && requiredForms.length > 0) {
        const user_id = jwt(localStorage.getItem("access-token"));
        const response = await axios.get(
          `submissions?user_id=${user_id["user_id"]}`,
          {
            headers,
          }
        );
        console.log("Get Submissions response", JSON.stringify(response.data));
        // Mapping the required forms with the pdf file name data from submissions in S3 bucket.
        requiredForms.map(async (element: any) => {
          // Find the first element that matches the substring (form name in this case '710', 'VASCCS' etc)
          element.id = response.data.filter((arr) =>
            arr.match(element.value)
          )[0];
          setRequiredForms(requiredForms);
          setLoading(false);
          console.log("REQUIRED FORMS: *", JSON.stringify(requiredForms));
        });
        console.log(
          "requiredForms.size Assigned to this user",
          requiredForms.length
        );
      } else {
        setLoading(false);
      }
    }
    if (status?.state?.status && status?.state?.status === "GENERATED") {
      fetchFilledForms();
    } else {
      fetchAndFillForms();
    }
  }, []);

  const upload = (item: {
    name: string;
    file: File;
  }): Promise<AxiosResponse<string>> => {
    const { file, name } = item;
    const formData = new FormData();
    formData.append("file", file);
    console.log({ formData });
    return axios.post<string>(`forms/uploads/${id}/${name}`, formData, {
      headers,
    });
  };

  const onFileUpload =
    (state: FileUploadState, additonalFiles: any[]): (() => void) =>
    async () => {
      setUploadLoading(true);
      const files = Object.keys(state).map((k) => ({
        name: k,
        file: state[k],
      }));
      const addnfiles = additonalFiles.map((file) => ({
        name: "addn_attach",
        file: file,
      }));
      const user_id = jwt(localStorage.getItem("access-token"));
      const formStatus = "SUBMITTED";
      await Promise.all(files.map(upload));
      // .then(() => axios.delete(`drafts/${id}`, { headers }))

      await Promise.all(addnfiles.map(upload))
        .then(() =>
          axios.put(`users/${user_id["user_id"]}`, { formStatus }, { headers })
        )
        .then(() => push("/complete"))
        .finally(() => setUploadLoading(false));
    };

  const fileUploadProps = { requiredForms, onFileUpload, id, loading };

  const { push } = useHistory();

  const completeForms = state.filter((form) => form.complete);

  const submit = ({ form, filename }: DraftSubmission): Promise<void> =>
    axios
      .post(`forms/${id}/${form}`, null, {
        headers,
        responseType: "arraybuffer",
      })
      .then(downloadFile({ type: "application/pdf", filename }));

  const fillAndUpload = async (form) => {
    const user_id = jwt(localStorage.getItem("access-token"));
    const formStatus = "GENERATED";
    const response = await axios.post(`forms/${id}/${form}`, null, {
      headers,
    });
    console.log("Fill and upload response", JSON.stringify(response.data.id));
    return response.data.id;
  };

  const getUserForms = async () => {
    const user_id = jwt(localStorage.getItem("access-token"));
    console.log("USERID *************", user_id);
    let userRes = await axios.get(`users/${user_id["user_id"]}`, { headers });
    let projectResponse;
    console.log("ProjectId:", userRes.data.projectId);
    if (userRes.data.projectId) {
      projectResponse = await axios.get(`projects/${userRes.data.projectId}`, {
        headers,
      });
      let formsResponse = await axios.get(`configs/forms`, { headers });
      let projectForms = Object.keys(projectResponse.data.forms);
      let forms = formsResponse.data;
      var requiredForms = forms.filter(function (el) {
        return projectForms.indexOf(el.value) >= 0;
      });
      return requiredForms;
    } else {
      // continue
    }
  };

  const fillAndUploadUserForms = async (requiredForms) => {
    //console.log('Before copy', JSON.stringify(requiredForms));
    // Delete existing user pdf documents before creating and uploading new ones.
    await axios.delete(`forms/${user_id["user_id"]}`, { headers });
    requiredForms.map(async (element: any) => {
      element.id = await fillAndUpload(element.value);
      console.log("Inside copy", JSON.stringify(requiredForms));
      setRequiredForms(requiredForms);
      setLoading(false);
    });
  };

  const downloadAll = (): ((event: any) => void) => (event) => {
    setDownloadLoading(true);
    event.preventDefault();
    axios
      .get(`submissions`, { headers, params: { user_id: user_id["user_id"] } })
      .then((response) => {
        axios
          .post(
            `submissions/download`,
            { keys: response.data },
            {
              headers,
              responseType: "arraybuffer",
            }
          )
          .then(
            downloadFile({
              type: "octet/stream",
              filename: `${username.replace(" ", "_")}_documents.zip`,
            })
          )
          .finally(() => {
            setDownloadLoading(false);
          });
      });
  };

  return (
    <div style={{ width: "75%" }}>
      <Spacer direction="column">
        <ToastContainer />
        {isUpload ? (
          <>
            <Typography variant="h5">Upload Signed PDF forms</Typography>
            <Typography variant="subtitle1">
              {loading || (requiredForms && requiredForms.length > 0)
                ? "Sign and upload. All forms must be completed and uploaded in order to submit. Review the forms one more time before uploading. Select the reviewed checkbox to enable the Upload PDF button"
                : "No forms assigned to you. Please contact Admin"}
            </Typography>
            <Divider />
            {requiredForms && requiredForms.length > 0 && (
              <Spacer direction="column">
                <div style={{ marginTop: "15px" }}>
                  <FileUpload
                    {...fileUploadProps}
                    type="upload"
                    onBack={() => setIsUpload(false)}
                    loading={uploadLoading}
                  />
                </div>
              </Spacer>
            )}
          </>
        ) : (
          <>
            <Typography variant="h5">
              Download security onboarding forms
            </Typography>
            <Typography variant="subtitle1" style={{ marginTop: "5px" }}>
              Next Steps
            </Typography>
            {loading || (requiredForms && requiredForms.length > 0) ? (
              <ul style={{ listStyleType: "disc", marginLeft: "15px" }}>
                {description.map((value: string) => (
                  <li>
                    <Typography variant="body1">{value}</Typography>
                  </li>
                ))}
                <li>
                  <Typography variant="body1">
                    If you close or navigate away from this screen your progress
                    will be saved, return to
                    {
                      <Link
                        href={`${window.location.href.split("/")[2]}`}
                        onClick={() => push(window.location.href.split("/")[2])}
                      >{` ${window.location.href.split("/")[2]} `}</Link>
                    }{" "}
                    to continue.
                  </Typography>
                </li>
                {specialFormsDescription.map((value: string) => (
                  <li>
                    <Typography variant="body1">{value}</Typography>
                  </li>
                ))}{" "}
              </ul>
            ) : (
              <Typography variant="subtitle1">
                {"No forms assigned to you. Please contact Admin"}
              </Typography>
            )}
            <Divider />
            {loading &&
              (status?.state?.status === "GENERATED" ? (
                <ClipLoader
                  color="FFFFF"
                  loading={loading}
                  css={override}
                  size={100}
                />
              ) : (
                <div>
                  <Progress value={progress} />
                  <Typography
                    style={{ textAlign: "center" }}
                    variant="subtitle2"
                  >
                    This might take a while, please wait.
                  </Typography>
                </div>
              ))}
            {requiredForms && requiredForms.length > 0 && (
              <Spacer direction="column">
                <Box width={"20%"} display="flex" alignContent={"center"}>
                  <Button
                    variant="contained"
                    style={{
                      marginTop: "15px",
                      marginRight: "2px",
                      alignSelf: "left",
                    }}
                    onClick={downloadAll()}
                    disabled={downloadLoading}
                  >
                    Download All
                  </Button>
                  {downloadLoading && (
                    <CircularProgress
                      style={{ marginTop: "15px" }}
                      size={35}
                      thickness={3}
                    />
                  )}
                </Box>
                <div style={{ marginTop: "15px" }}>
                  <FileUpload
                    {...fileUploadProps}
                    type="download"
                    onNext={() => setIsUpload(true)}
                  />
                </div>
              </Spacer>
            )}
          </>
        )}
      </Spacer>
    </div>
  );
};

export default Overview;
